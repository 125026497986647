// Core libraries
import React, { useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  MapContainer,
  TileLayer,
} from 'react-leaflet';
import { LatLng } from 'leaflet';


// Internal libs
import config from 'app/config';
import { CompanyLocations } from 'model/CompanyLocations';

// Internal components
import CompanyMarkers from './CompanyMarkers';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    mapContainer: {
      width: '100%',
      height: '100%',
    },
  }),
);

// Props type
type LocationsMapProps = {
  companiesLocations: CompanyLocations[];
  initZoom?: number;
  initCenter?: LatLng | [number, number];
};

// Component
const LocationsMap = ({
  companiesLocations,
  initZoom = config.mapZoom,
  initCenter = config.mapCenter,
}: LocationsMapProps) => {
  const styles = useStyles();

  const [center] = useState(initCenter);
  const [zoom] = useState(initZoom);

  return (
      <MapContainer center={center} zoom={zoom} className={styles.mapContainer}>
        {/* @ts-ignore */}
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {companiesLocations?.map((companyLocation) => (
          <CompanyMarkers companyLocations={companyLocation} key={companyLocation.companyId} />
        ))}
      </MapContainer>
  );
};

export default LocationsMap;
