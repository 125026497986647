import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  palette: {
    // primary: {
    //   main: '#524EFF',
    // },
    // text: {
    //   primary: '#242A3F',
    // },
  },
});
