import { CompanyLocations } from "../model/CompanyLocations";
import axios from 'axios';

export const getLocationsByCompanies = async (
  companyIds?: string | string[]
): Promise<CompanyLocations[]> => {
  companyIds = Array.isArray(companyIds)
    ? companyIds
    : companyIds
    ? [companyIds]
    : undefined;

  const response = await axios.get('/region/locations', {params: {companyId: companyIds}});

  return response?.data?.list;
};
