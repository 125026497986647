import React from 'react';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'app/api';
import { theme } from 'app/styles/theme';
import LocationsPage from 'pages/Locations/LocationsPage';

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <LocationsPage />
    </MuiThemeProvider>
  );
}

export default App;
