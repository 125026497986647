import axios from 'axios';
import config from 'app/config';

axios.interceptors.request.use((requestConfig) => {
  const absolute = !!requestConfig.url && /^([A-z]+:\/\/)/.test(requestConfig.url);

  const apiBaseUrl = `${config.backendUrl}/api`;


  if (!absolute && !requestConfig?.baseURL) {
    requestConfig.baseURL = apiBaseUrl;
    requestConfig.headers['Content-Type'] = 'application/json';
    requestConfig.headers['Accept'] = 'application/json';
  }

  return requestConfig;
});
