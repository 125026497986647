// Core libraries
import React, { useCallback, useEffect, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress'

// Internal libs
import config from 'app/config';

// Internal components
import { ReactComponent as Logo } from 'assets/xd_logo_long.svg';
import LocationsMap from './components/LocationsMap';
import { InProgressState } from '../../model/InProgressState';
import { CompanyLocations } from '../../model/CompanyLocations';
import * as api from '../../api';

// Component
const LocationsPage = () => {
  const styles = useStyles();

  const [getDataState, setGetDataState] = useState<InProgressState>({
    inProgress: false,
  });
  const [companiesLocations, setCompaniesLocations] =
    useState<CompanyLocations[] | undefined>(undefined);

  const fetchLocations = useCallback(async () => {
    setGetDataState({ inProgress: true });
    try {
      setCompaniesLocations(
        await api.getLocationsByCompanies(
          config.companies.map((companyConfig) => companyConfig.companyId),
        ),
      );
      setGetDataState({ inProgress: false, success: true });
    } catch (error) {
      setGetDataState({ inProgress: false, success: false, error });
    }
  }, []);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  return (
    <Container className={styles.root} maxWidth={false} disableGutters>
      <div className={styles.topBar}>
        <Logo className={styles.logo} title={'Xpress →Delivery'} />
      </div>
      <div className={styles.message}>
        Miejsca:{' '}
        {getDataState.inProgress && <CircularProgress size={16} thickness={6}/>}
        {getDataState.error && "wystąpił problem podczas wczytywania listy miejsc"}
        {companiesLocations &&
          companiesLocations.map((companyLocations, idx) => {
            const color = config.companies.find((c) => (c.companyId = companyLocations.companyId))?.color;
            const last = idx === companiesLocations.length - 1;
            return (
              <React.Fragment key={idx}>
                <span {...(color ? { style: {color} } : {})}>{companyLocations.companyName}</span>
                {!last && ', '}
              </React.Fragment>
            );
          })}
      </div>
      <div className={styles.mapContainer}>
        <LocationsMap companiesLocations={companiesLocations || []} />
      </div>
      <div className={styles.footer}>
        <span>© Xpress →Delivery</span>
      </div>
    </Container>
  );
};

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100vh',
    },
    topBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    message: {
      textAlign: 'center',
      marginBottom: theme.spacing(2),
      fontSize: '1.2em',
    },
    logo: {
      height: 'auto',
      width: (32 * 600) / 58,
      maxWidth: '90%',
    },
    mapContainer: {
      flexGrow: 1,
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
  }),
);

export default LocationsPage;
