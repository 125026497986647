import { CompanyConfig } from '../model/CompanyConfig';

const config: {
  backendUrl: string;
  googleMapsApiKey: string;
  companies: CompanyConfig[];
  mapCenter: [number, number];
  mapZoom: number;
} = {
  backendUrl: process.env.REACT_APP_BACKEND_URL || '',
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',

  companies: JSON.parse(process.env.REACT_APP_COMPANIES || '[]'),
  mapCenter: (process.env.REACT_APP_MAP_CENTER_LAT_LNG &&
    JSON.parse(process.env.REACT_APP_MAP_CENTER_LAT_LNG)) || [51.9194, 19.1451],
  mapZoom: (process.env.REACT_APP_MAP_ZOOM && parseInt(process.env.REACT_APP_MAP_ZOOM)) || 7,
};

export default config;
