// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import config from 'app/config';
import { Popup, Circle, FeatureGroup } from 'react-leaflet';

// Internal libs

// Internal components
import { CompanyLocations } from 'model/CompanyLocations';

// Props type
type CompanyMarkersProps = { companyLocations: CompanyLocations };

// Component
const CompanyMarkers = ({ companyLocations }: CompanyMarkersProps) => {
  const companyConfig = useMemo(
    () => config.companies.find((c) => (c.companyId = companyLocations.companyId)),
    [companyLocations],
  );

  return (
    <>
      {companyLocations.locations.map((location, idx) => (
        <FeatureGroup key={idx}>
          <Popup>{location.name}<br/>{location.addressFormatted}</Popup>
          <Circle
            center={[location.coordinates[1], location.coordinates[0]]}
            radius={
              (location.deliverySettings.maxDestinationDistanceRadius ||
                companyLocations.deliverySettings.maxDestinationDistanceRadius ||
                0) * 1000
            }
            {...(companyConfig?.color ? {fillColor: companyConfig.color} : {})}
            {...(companyConfig?.color ? {color: companyConfig.color} : {})}
          />
        </FeatureGroup>
      ))}
    </>
  );
};

export default CompanyMarkers;
